import about_img_1 from "../img/about_img_1.png";
import about_img_2 from "../img/about_img_2.png";
import about_img_3 from "../img/about_img_3.png";
import about_img_4 from "../img/about_img_4.png";

export const SLIDES = [
  {
    slides: [
      {
        src: `${about_img_1}`,
        alt: "Image 1 for carousel",
      },
      {
        src: `${about_img_2}`,
        alt: "Image 2 for carousel",
      },
      {
        src: `${about_img_3}`,
        alt: "Image 3 for carousel",
      },
      {
        src: `${about_img_4}`,
        alt: "Image 4 for carousel",
      },
    ],
  },
];
